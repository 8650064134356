import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, Link, graphql } from 'gatsby'
import get from 'lodash/get'

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulPortfolioItem(sort: { fields: [createdAt], order: ASC }) {
          edges {
            node {
              screenshots {
                file {
                  url
                },
                fluid(maxWidth: 960){
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );
  const src = get(data, 'allContentfulPortfolioItem.edges.0.node.screenshots.0');

  return (
    <div className="main banner">
      <div className='hero banner-content'>
          <div className="left" style={{width: '430px'}} >
              <h1 className="center mt30">Hello Beautiful!</h1>
              <p>At Design Magic Studios, we are passionate about exceptional website design and application development. We focus on providing you with new, engaging, and effective ways of interacting with your visitors. Let us run with your project, and see just how great web development is done these days.</p>
              <p className="center pt20">
                  <a className="blue button" href="/contact-us">Start your project today! →</a>
              </p>
          </div>
          <ul className="left innerfade" id="featured-gallery">
            <li>
              <Img className='gallery-item' fluid={src.fluid} />
            </li>
          </ul>
          <div className="clear"></div>
      </div>
    </div>
  )
}
